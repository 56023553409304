// Application class
class RudderApp {
  constructor() {
    this.build = "1.0.0";
    this.name = "RudderLabs JavaScript SDK";
    this.namespace = "com.rudderlabs.javascript";
    this.version = "process.package_version";
  }
}
export default RudderApp;
